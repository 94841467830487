import { defineStore } from 'pinia'
import { Big } from 'big.js'
import { getBullets, getMarketBulletList } from '@deorderbook/sdk'
import type { BasicTokenType } from '@deorderbook/shared'
import type { Bullet, MarketBullet } from '@deorderbook/sdk/subgraph-api'
import { getBestOffer as getMarketBulletWithBestOffer } from '@deorderbook/sdk/ethereum/market_bullet'
import type { BestOffer } from '@base/types/bullet'

// get user's bullets
export const useBulletsStore = defineStore('bullets', () => {
  const ONE_DAY = 24 * 60 * 60
  const loading = ref(false)
  const bullets: Ref<Bullet[]> = ref([])
  const marketBullets: Ref<MarketBullet[]> = ref([])
  const bulletBestOffers: Ref<BestOffer[]> = ref([])
  const updatedAt = ref(0)
  // TODO: set a common duration
  const updateDuration = 15 * 1000

  const { getTokenEnums } = useTokens()

  onWalletReady(
    () => {
      actionRefreshBullets()
    },
    {
      status: 'setup',
    },
  )

  async function actionRefreshBullets(force = false) {
    if (
      force ||
      (loading.value === false &&
        Date.now() - updatedAt.value >= updateDuration)
    ) {
      loading.value = true
      marketBullets.value = []
      await getBullets()
        .then((resp) => {
          bullets.value = resp
        })
        .catch((err) => {
          console.error(err)
        })
      await getMarketBulletList()
        .then((resp) => {
          marketBullets.value = resp
        })
        .catch((err) => {
          console.error(err)
        })
      await updateBestOffers()
        .then((resp) => {
          bulletBestOffers.value = resp
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          updatedAt.value = Date.now()
          loading.value = false
        })
    }
  }

  const updateBestOffers = async () => {
    const bestOffers: BestOffer[] = []
    const activeBullets = bullets.value.filter((bullet) => {
      const exercisablePeriodEndsAt = useTimestamp(
        (+bullet.exerciseTimestamp + ONE_DAY).toString(),
        {
          unit: 'milliseconds',
        },
      )
      return +exercisablePeriodEndsAt > Date.now()
    })
    for (const bullet of activeBullets) {
      const buyTokenObj = getTokenEnums().uHODL
      const bestOfferMarketBulletId = await getMarketBulletWithBestOffer(
        bullet.id,
        buyTokenObj.address,
      )
      const bestOffer = marketBullets.value.find(
        (marketBullet) =>
          +marketBullet.markerBulletId === Number(bestOfferMarketBulletId),
      )
      bestOffers.push({
        address: bullet.id,
        bestOffer: bestOffer
          ? Big(bestOffer?.totalSellValue || 0)
              .div(bestOffer?.sellAmount)
              .toFixed()
          : undefined,
        bestOfferToken: buyTokenObj.symbol as BasicTokenType,
      })
    }
    return bestOffers
  }
  return {
    loading,
    bullets,
    marketBullets,
    bulletBestOffers,
    actionRefreshBullets,
    updateBestOffers,
  }
})
